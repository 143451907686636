import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import IconWrapper from 'atoms/IconWrapper';
import './carousel.scss';
const PaddingDot = ({ active, onClick }) => <IconWrapper style={{ margin: '0 5px', cursor: 'pointer' }} type={active ? 'GreenDot' : 'GreyDot'} onClick={onClick} />;

const LeftRightArrow = ({ index, totalLength, setIndex, top }) => (
  <div className={`justify-content-center mt-4 ${top && 'mb-4'} forward-backward-icons`}>
    <IconWrapper
      type="MoveBackward"
      onClick={() => {
        if (index > 0) setIndex(index - 1);
      }}
    />
    <span>
      {index + 1} / {totalLength}
    </span>
    <IconWrapper
      type="MoveForward"
      onClick={() => {
        if (index < totalLength - 1) setIndex(index + 1);
      }}
    />
  </div>
);

LeftRightArrow.propTypes = {
  index: PropTypes.number,
  totalLength: PropTypes.number,
  setIndex: PropTypes.func,
  top: PropTypes.bool
};

PaddingDot.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
};

const CustomCarousel = ({ children, alignDots, onChangeSlide, enableNextPrevIcons = true, enableSlideNextPrev = false, ...props }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
    onChangeSlide(selectedIndex);
  };

  const updatedChildren = useMemo(() => {
    return React.Children.map(children, child => <Carousel.Item>{child}</Carousel.Item>);
  }, children);

  const paddingDots = useMemo(
    () =>
      React.Children.map(children, (child, i) => (
        <PaddingDot
          onClick={() => {
            handleSelect(i);
            setIndex(i);
          }}
          active={i === index}
        />
      )),
    [children, index]
  );

  return (
    <>
      {enableSlideNextPrev && <LeftRightArrow index={index} totalLength={updatedChildren.length} setIndex={handleSelect} top={true} />}
      {alignDots === 'top' && <div className="justify-content-center mt-4 mb-4">{paddingDots}</div>}
      <Carousel
        keyboard={true}
        interval={null}
        wrap={false}
        slide={false}
        fade={true}
        indicators={false}
        touch={false}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={enableNextPrevIcons && <IconWrapper type="MoveForward" />}
        prevIcon={enableNextPrevIcons && <IconWrapper type="MoveBackward" />}
        {...props}
      >
        {updatedChildren}
      </Carousel>
      {alignDots === 'bottom' && <div className="justify-content-center mt-4 mb-4">{paddingDots}</div>}
      {enableSlideNextPrev && <LeftRightArrow index={index} totalLength={updatedChildren.length} setIndex={handleSelect} />}
    </>
  );
};

CustomCarousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  alignDots: PropTypes.oneOf(['top', 'bottom']),
  onChangeSlide: PropTypes.func,
  enableNextPrevIcons: PropTypes.bool,
  enableSlideNextPrev: PropTypes.bool
};

CustomCarousel.defaultProps = {
  alignDots: '',
  onChangeSlide: () => {}
};
export default CustomCarousel;
