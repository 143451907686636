import React from 'react';
import { Card } from 'react-bootstrap';
import cn from 'classnames';

const Options = ({ mcq, disabled, onChangeOption, selectedKey, correctKey }) => {
  return mcq.options.map(option => {
    return (
      <Card.Text key={option.id}>
        <input
          type="radio"
          role="radio"
          tabIndex={-1}
          id={`radio-group-${option.id}`}
          value={option.id}
          disabled={disabled}
          name={`radio-group-${mcq._id}`}
          onChange={onChangeOption}
          checked={selectedKey === option.id}
          className={cn('mixed-mcq-item-container__button', {
            'mixed-mcq-item-container__button--disabled': disabled,
            'mixed-mcq-item-container__button--correct': correctKey === option.id
          })}
        />
        <label
          tabIndex={0}
          onKeyPress={e => {
            if (e.key === ' ' && !disabled) {
              e.preventDefault();
              onChangeOption({
                target: { value: option.id }
              });
            }
          }}
          htmlFor={`radio-group-${option.id}`}
          className={cn('mixed-mcq-item-container__label', {
            'mixed-mcq-item-container__label--disabled': disabled,
            'mixed-mcq-item-container__label--correct': correctKey === option.id
          })}
        >
          {(typeof option === 'string' ? option : option?.answer) || ''}
        </label>
      </Card.Text>
    );
  });
};
export default Options;
