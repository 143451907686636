import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';

import ThreeDots from 'atoms/ThreeDots';
import IconWrapper from 'atoms/IconWrapper';
import LanguageMenu from './LanguageMenu';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { formatLanguages } from 'utils/calculationUtilities';
import { UserPropTypesShape, HistoryPropTypesShape, LocationPropTypesShape } from 'utils/core-proptypes';
import AppContext from '../../app-context';
import { logout, showBrowserError, updatePublicUserSettings } from './actions';
import { updateUserSettings, fetchPublications } from '../../actions';
import './navigation.scss';

const NavigationBarComponent = ({ userDetails, fetchPublications, publications, showBrowserError, isAuthenticated, ...props }) => {
  const t = useFormatMessage();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const context = useContext(AppContext);

  const { user: { role = '', organizationName } = {} } = userDetails;
  useEffect(() => {
    if (isEmpty(publications)) {
      fetchPublications();
    }
    if (isMobile && ['ADMIN', 'MANAGER', 'PARTNER'].includes(role)) {
      showBrowserError();
    }
  }, []);

  // useEffect(() => {
  //   const userLanguage = get(userDetails, 'user.settings.defaultLangCode', 'en');
  //   if (context.language !== userLanguage) {
  //     context.setContextState({ language: userLanguage });
  //   }
  // }, [userDetails]);

  const handleClick = () => {
    props.logout();
  };

  const handleKeySignout = e => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const handleChangeLanguage = languageId => {
    if (isAuthenticated) props.updateUserSettings({ defaultLangCode: languageId });
    else {
      context.setContextState({ language: languageId });
      props.updatePublicUserSettings(languageId);
    }
  };

  const { pathname } = props.location;
  if (['/', '/signin', '/reset-password', '/set-password'].includes(pathname)) return null;
  return (
    <Navbar id="primary-navigation" className="navigation-container" collapseOnSelect expand="sm" bg="white" variant="light" style={{ padding: '.5rem 1.5rem .5rem 1.5rem' }}>
      {!isMobile ? (
        <Navbar.Brand
          style={{ fontSize: '14px', paddingLeft: '15px', paddingRight: '15px', cursor: 'pointer' }}
          tabIndex={0}
          onClick={() => {
            props.history.push('/home');
          }}
        >
          {organizationName}
        </Navbar.Brand>
      ) : null}
      <Nav className="right-side-navigation">
        <>
          {isAuthenticated && (
            <>
              <Nav.Link as="div" className="right-side-navigation__navlink">
                <NavLink to="/home">{isMobile ? <IconWrapper type="HomeIcon" /> : t('navigation/home')}</NavLink>
              </Nav.Link>
              <Nav.Link as="div" className="right-side-navigation__navlink">
                <NavLink to="/my-account?q=my-achievements">{isMobile ? <IconWrapper type="MyAccount" /> : t('navigation/my-account')}</NavLink>
              </Nav.Link>
            </>
          )}
          <NavItem className="right-side-navigation__navlink nav-link">
            {isMobile ? (
              <ThreeDots src="LanguageSelector" width="300px" onSelect={handleChangeLanguage} placement="bottom" options={publications}>
                <IconWrapper type="LanguageSelector" />
              </ThreeDots>
            ) : (
              <LanguageMenu isMobile={isMobile} onSelect={handleChangeLanguage} publications={publications} />
            )}
          </NavItem>
          {isAuthenticated && (
            <div onClick={handleClick} onKeyPress={handleKeySignout} as="div" tabIndex={0} className="right-side-navigation__navlink">
              {isMobile ? <IconWrapper type="LogoutIcon" /> : t('navigation/sign-out')}
            </div>
          )}
        </>
      </Nav>
    </Navbar>
  );
};

NavigationBarComponent.propTypes = {
  location: LocationPropTypesShape,
  history: HistoryPropTypesShape,
  logout: PropTypes.func.isRequired,
  showBrowserError: PropTypes.func.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
  updatePublicUserSettings: PropTypes.func.isRequired,
  fetchPublications: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  publications: PropTypes.arrayOf(PropTypes.shape({})),
  userDetails: UserPropTypesShape
};

NavigationBarComponent.defaultProps = {
  location: {
    pathname: ''
  },
  history: {}
};

const mapStateToProps = ({ adminState, userState = {} }) => {
  const isAuthenticated = !!get(userState, 'user.user._id', false);

  return {
    publications: formatLanguages(get(adminState, 'publications', [])),
    userDetails: userState.user || {},
    isAuthenticated
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: bindActionCreators(logout, dispatch),
    showBrowserError: bindActionCreators(showBrowserError, dispatch),
    updateUserSettings: bindActionCreators(updateUserSettings, dispatch),
    updatePublicUserSettings: bindActionCreators(updatePublicUserSettings, dispatch),
    fetchPublications: bindActionCreators(fetchPublications, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBarComponent));
