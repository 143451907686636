import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { getPriortizedDataAccordingToLanguage, formatLanguages } from 'utils/calculationUtilities';
import PropTypes from 'prop-types';
import { ContentsPropTypesShape, CurrentContentPropTypesShape, CurrentModulepropTypesShape } from 'utils/core-proptypes';

const Breadcrumbs = ({ currentModule, currentContent, userDefaultLangCode }) => {
  const location = useLocation();
  const { moduleTitle = '' } = !isEmpty(currentModule) ? getPriortizedDataAccordingToLanguage([userDefaultLangCode, currentModule.defaultLangCode], currentModule.data) : {};
  const currentModuleId = currentModule?._id;
  const contentTitle = get(currentContent, 'content.data.details.contentTitle', '');
  // Define a function to get breadcrumb names for static paths

  // Split the current pathname into parts to build breadcrumbs
  const pathnames = location.pathname.split('/').filter(x => x);
  let breadcrumbs = [];
  let homeBreadCrumb = pathnames?.[0] === 'home' ? true : false;
  if ('content-list' === pathnames?.[0]) {
    homeBreadCrumb = true;
    breadcrumbs = [
      <span key={`/cotent-list/${currentModuleId}`}>
        {' > '}
        <Link to={`/cotent-list/${currentModuleId}`}>{moduleTitle}</Link>
        {/* {index < pathnames.length - 1 && ' > '} */}
      </span>
    ];
  }
  if ('my-account' === pathnames?.[0]) {
    homeBreadCrumb = true;
    const selectedTab = location.search === '?q=my-achievements' ? 'My Achievements' : 'My Settings';
    breadcrumbs = [
      <span key={`/my-account`}>
        {' > '}
        <Link>{`My Account / ${selectedTab}`}</Link>
        {/* {index < pathnames.length - 1 && ' > '} */}
      </span>
    ];
  }
  if (['mcq', 'story', 'carousel', 'presentation', 'mixed'].includes(pathnames?.[0])) {
    homeBreadCrumb = true;
    breadcrumbs = [
      <span key={`/cotent-list/${currentModuleId}`}>
        {' > '}
        <Link to={`/cotent-list/${currentModuleId}`}>{moduleTitle}</Link>
        {/* {index < pathnames.length - 1 && ' > '} */}
      </span>,
      <span key={`pathnames?.[0]`}>
        {' > '}
        <Link>{contentTitle}</Link>
        {/* {index < pathnames.length - 1 && ' > '} */}
      </span>
    ];
  }

  // Add the parent breadcrumb link for the current page
  return (
    <div className="breadcrumbs">
      {homeBreadCrumb && <Link to="/">Home</Link>}
      {breadcrumbs.length > 0 && breadcrumbs}
    </div>
  );
};

Breadcrumbs.propTypes = {
  contents: ContentsPropTypesShape,
  currentModule: CurrentModulepropTypesShape,
  currentContent: CurrentContentPropTypesShape,
  isAuthenticated: PropTypes.bool.isRequired,
  userDefaultLangCode: PropTypes.string.isRequired
};

const mapStateToProps = ({ userState, userActivityState, adminState }) => {
  const { currentModule, currentContent = {} } = userActivityState;
  const isAuthenticated = !!get(userState, 'user.user._id', false);
  const languages = get(adminState, 'localLanguages', []);
  const formattedLanguageList = formatLanguages(languages);
  const defaultLangCodeUser = get(userState, 'user.user.settings.defaultLangCode');

  return {
    currentModule,
    isAuthenticated,
    currentContent,
    languages: formattedLanguageList,
    userDefaultLangCode: defaultLangCodeUser
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
