import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tabs, Tab } from 'react-bootstrap';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { dateValues } from 'utils/calculationUtilities';
import MyAchievements from './../MyAchievements';
import MySettings from './../MySettings';

const SettingsDashboard = ({ ...props }) => {
  const t = useFormatMessage();
  return (
    <React.Fragment>
      <Container style={{ padding: 0 }}>
        <Tabs id="users-settings-tab">
          <Tab className="tab1" eventKey="my-achievements" aria-labelledby="my-achievements-tab" title={t('account-settingns/my-achievements')}>
            <MyAchievements {...props} />
          </Tab>
          <Tab eventKey="my-settings" aria-labelledby="my-settings-tab" title={t('account-settingns/my-settings')}>
            <MySettings {...props} dateValues={dateValues} />
          </Tab>
        </Tabs>
      </Container>
    </React.Fragment>
  );
};

SettingsDashboard.propTypes = {
  role: PropTypes.string.isRequired
};

SettingsDashboard.defaultProps = {};
export default SettingsDashboard;
