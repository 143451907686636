import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { useFormatMessage } from 'hooks/useFormatMessage';
import Button from 'atoms/Button';
import { HistoryPropTypesShape } from 'utils/core-proptypes';
import PublicDiplomaDownload from '../../PublicDiplomaDownload';

const DiplomaDownloadLink = ({ isAuthenticated, history }) => {
  const [showDiploma, setShowDiploma] = useState(false);
  const t = useFormatMessage();

  return (
    <Container className="MCQStatus">
      <>
        <Row className="MCQStatus__col">
          <Link
            onClick={() => {
              if (!isAuthenticated) {
                setShowDiploma(true);
              } else {
                history.replace('/my-account?q=my-achievements');
              }
            }}
          >
            <Button variant="primary" style={{ padding: ' 15px 40px', marginTop: '20px', borderRadius: '.5rem' }}>
              {t('diploma-link/well-done-get-diploma')}
            </Button>
          </Link>
        </Row>
        <PublicDiplomaDownload setShowDiploma={setShowDiploma} showDiploma={showDiploma} />
      </>
    </Container>
  );
};

DiplomaDownloadLink.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  history: HistoryPropTypesShape
};

DiplomaDownloadLink.defaultProps = {};

export default withRouter(DiplomaDownloadLink);
