import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { useFormatMessage } from 'hooks/useFormatMessage';

import VideoViewer from '../../VideoView';
import Response from 'atoms/Response';
import Explanation from 'atoms/Explanation';
import Options from './Options';

const OverlayMCQ = ({ handleRetry }) => {
  const t = useFormatMessage();

  return (
    <div style={{ position: 'absolute', zIndex: '9', height: '100%', width: '100%', textAlign: 'center', top: '35%' }}>
      <p style={{ color: '#FFF' }}>{t('retry-overlay/sorry-but-an-error-occured.Please-submit-your-answer-again')}</p>
      <Button width="50px" variant="secondary" onClick={() => handleRetry()}>
        {t('retry-overlay/retry')}
      </Button>
    </div>
  );
};
const Question = ({
  index,
  showQuestionNumber,
  totalQuestions,
  mcq,
  explanation,
  selectedKey,
  correctKey,
  showResponse,
  isOptionTouched,
  isOptionsDisabled,
  onChangeOption,
  handleRetry,
  showError
}) => {
  return (
    <Fragment>
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={10} sm={12}>
          {showQuestionNumber && (
            <div>
              {index + 1}/{totalQuestions}
            </div>
          )}
          <div style={showError ? { background: '#F05F5A', marginBottom: '20px', borderRadius: '15px' } : { marginBottom: '20px' }}>
            {showError && <OverlayMCQ handleRetry={handleRetry} />}

            <Card className="mcq-container" style={showError ? { opacity: '.09', background: 'transparent' } : {}}>
              <Card.Body
                className={cn('mcq-container__body', {
                  'mcq-container__body--wrong': isOptionTouched && !showResponse
                })}
              >
                {mcq.media && (
                  <div className="mcq-container__media">
                    <VideoViewer media={mcq.media} showHideButton={false} fullWidth={true} />
                  </div>
                )}
                <Card.Title>{mcq.question}</Card.Title>
                <div role="radio-group">
                  <Options mcq={mcq} disabled={isOptionsDisabled} correctKey={correctKey} onChangeOption={onChangeOption} selectedKey={selectedKey} />
                </div>
                {isOptionTouched && (
                  <div className="mcq-container__info">
                    <Response isCorrect={showResponse} />
                    <Explanation explanation={explanation} />
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

Question.propTypes = {
  index: PropTypes.number,
  showQuestionNumber: PropTypes.bool,
  totalQuestions: PropTypes.number,
  mcq: PropTypes.shape({
    question: PropTypes.string,
    media: PropTypes.shape({})
  }),
  explanation: PropTypes.string,
  selectedKey: PropTypes.string,
  correctKey: PropTypes.string,
  showResponse: PropTypes.bool,
  isOptionTouched: PropTypes.bool,
  showError: PropTypes.bool,
  isOptionsDisabled: PropTypes.bool,
  onChangeOption: PropTypes.func,
  handleRetry: PropTypes.func
};

OverlayMCQ.propTypes = {
  handleRetry: PropTypes.func
};

export default Question;
