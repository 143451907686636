import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllDiplomas } from './actions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import DiplomaCard from '../DiplomaCard';
import { UserPropTypesShape } from 'utils/core-proptypes';
import './my-achievements.scss';

const MyAchievements = ({ getAllDiplomas, diplomas, organization, userProfile }) => {
  useEffect(() => {
    getAllDiplomas();
  }, []);
  return (
    <React.Fragment aria-labelledby="my-achievements-tab">
      <Container className="my-achievements__container">
        <Row>
          {diplomas.map(diploma => {
            return (
              <Col className="my-achievements__card" key={diploma.diplomaData.diplomaId} lg={3} md={6} sm={12}>
                <DiplomaCard diploma={diploma} organization={organization} userProfile={userProfile} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </React.Fragment>
  );
};

MyAchievements.propTypes = {
  getAllDiplomas: PropTypes.func.isRequired,
  diplomas: PropTypes.arrayOf(PropTypes.shape({})),
  organization: PropTypes.shape({ name: PropTypes.string }).isRequired,
  userProfile: UserPropTypesShape
};

MyAchievements.defaultProps = {};

const mapStateToProps = ({ myAccountState, userState }) => {
  return {
    diplomas: myAccountState.diplomas,
    organization: myAccountState.organization,
    userProfile: userState.user.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllDiplomas: bindActionCreators(getAllDiplomas, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAchievements));
