import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormatMessage } from 'hooks/useFormatMessage';
import IconWrapper from 'atoms/IconWrapper';
import DiplomaDownloadLink from '../../DiplomaDownloadLink';

import './mcq-status.scss';
import { Button, Container } from 'react-bootstrap';

const MCQStatus = ({ attempted, total, passCount, isPreview, isAuthenticated, showContentOnDiploma, displayQuestionMode, showResult, setShowResult, ...props }) => {
  const t = useFormatMessage();
  const totalAttempted = Object.keys(attempted);
  const totalQuestions = total;

  const correct = totalAttempted.filter(key => attempted[key].success);
  const passed = correct.length >= passCount;
  const message = passed ? t('mcq-view-status/you-passed') : t('mcq-view-status/sorry-you-did-not-pass');

  return (
    <Container>
      <Col lg={10} className="MCQStatus__row">
        {totalAttempted.length === totalQuestions &&
          (displayQuestionMode === 'ONE_BY_ONE' && !showResult ? (
            <CongratulationsCard setShowResult={setShowResult} attempted={attempted} total={total} passCount={passCount} {...props} />
          ) : (
            <>
              <Row className="MCQStatus__col" lg={8} sm={12}>
                <span className="MCQStatus__questions--correct">{correct.length}</span>
                <IconWrapper type="Right" alt="Correct" className="MCQStatus__questions__icon--correct" />
                <IconWrapper type={passed ? 'ThumbsUp' : 'ThumbsDown'} alt={message} />
                <IconWrapper type="Wrong" alt="Wrong" className="MCQStatus__questions__icon--wrong" />
                <span className="MCQStatus__questions--wrong">{totalQuestions - correct.length}</span>
              </Row>
              {passed && !isPreview && showContentOnDiploma === 'true' && <DiplomaDownloadLink isAuthenticated={isAuthenticated} />}
            </>
          ))}
      </Col>
    </Container>
  );
};

const CongratulationsCard = ({ noOfTestAttempts, redirectToContentList, startContentAgain, attempted, total, passCount, setShowResult }) => {
  const t = useFormatMessage();
  const totalAttempted = Object.keys(attempted);
  const totalQuestions = total;

  const correct = totalAttempted.filter(key => attempted[key].success);
  const passed = correct.length >= passCount;
  const message = passed ? t('mcq-view-status/you-passed') : t('mcq-view-status/sorry-you-did-not-pass');

  return (
    <Row className="result-display-container">
      {totalAttempted.length === totalQuestions && (
        <>
          <Col lg={10} md={11} sm={12} className="result-display-container__upper-box">
            <h1>{passed ? t('mcq-view/congratulations-you-passed') : t('mcq-view/you-didnt-pass')}</h1>
            {passed ? t('mcq-view/congratulations-text') : t('mcq-view/failure-text')}

            <Row className="result-display-container__row" lg={8} sm={12}>
              <span className="result-display-container__questions--correct">{correct.length}</span>
              <IconWrapper type="Right" alt="Correct" className="result-display-container__questions__icon--correct" />
              <IconWrapper type={passed ? 'ThumbsUp' : 'ThumbsDown'} alt={message} />
              <IconWrapper type="Wrong" alt="Wrong" className="result-display-container__questions__icon--wrong" />
              <span className="result-display-container__questions--wrong">{totalQuestions - correct.length}</span>
            </Row>
            <Button
              onClick={() => {
                setShowResult(true);
              }}
              className="result-display-container__button"
            >
              {t('mcq-status/show-my-response')}
            </Button>
          </Col>
          <Col className="result-display-container__bottom-buttons p-0">
            {noOfTestAttempts !== 'ONE' && (
              <Button
                variant="link"
                style={{ lineHeight: '1', color: '#00aaa4' }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    startContentAgain();
                  }
                }}
                onClick={startContentAgain}
              >
                {t('mcq-status/reload-the-lesson')}
              </Button>
            )}
            <Button variant="link" style={{ lineHeight: '1', color: '#00aaa4' }} onClick={redirectToContentList}>
              {t('mcq-status/back-to-course')}
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

MCQStatus.propTypes = {
  attempted: PropTypes.shape({}).isRequired,
  total: PropTypes.number.isRequired,
  displayQuestionMode: PropTypes.number.isRequired,
  passCount: PropTypes.number.isRequired,
  isPreview: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showResult: PropTypes.bool.isRequired,
  showContentOnDiploma: PropTypes.string.isRequired,
  setShowResult: PropTypes.string.isRequired
};

CongratulationsCard.propTypes = {
  setShowResult: PropTypes.string.isRequired,
  redirectToContentList: PropTypes.func.isRequired,
  startContentAgain: PropTypes.func.isRequired,
  noOfTestAttempts: PropTypes.number.isRequired,
  attempted: PropTypes.shape({}).isRequired,
  total: PropTypes.number.isRequired,
  passCount: PropTypes.number.isRequired,
  isPreview: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showContentOnDiploma: PropTypes.string.isRequired
};

MCQStatus.defaultProps = {};

export default MCQStatus;
