import React, { useContext } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { IntlProvider } from 'react-intl';
import locale from './locale';

import AppContext from './app-context';
import AuthenticatedRoute from './components/AuthRoute';
import SignInPage from './components/SignInPage';
import MCQView from './components/AttemptsAndPreview/McqView';
import MixedView from './components/AttemptsAndPreview/MixedView';
import PresentationView from './components/AttemptsAndPreview/PresentationView';
import CarouselView from './components/AttemptsAndPreview/CarouselView';
import StoryView from './components/AttemptsAndPreview/StoryView';
import Home from './components/Home';
import UsersAndGroups from './components/UsersAndGroups';
import ModulesContentDashboard from './components/ModulesAndContent';
import UserSettings from './components/UserSettings';
import AccountSettings from './components/AccountSettings';
import AppSettings from './components/AppSettings';
import NavigationBar from './components/NavigationBar';
import GlobalNotification from './components/GlobalNotification';
import ContentCreate from './components/ModulesAndContent/ContentCreate';
import ContentList from './components/ContentList';
import ModuleCreate from './components/ModulesAndContent/ModuleCreate';
import SecondaryNavigation from './components/SecondaryNavigation';
import RoleCheck from './components/RoleCheck';
import Footer from './components/Footer';
import store from 'utils/store';
import ScrollToTop from './components/atoms/ScrollToTop';
import ErrorBoundary from './ErrorBoundary/index';
import ErrorComp from './ErrorBoundary/ErrorComp';
import Statistics from './components/Statistics';
import Breadcrumbs from './BreadCrumbs';

const App = () => {
  const context = useContext(AppContext);
  return (
    <Provider store={store}>
      <IntlProvider locale={context.language} messages={locale[context.language]}>
        <ErrorBoundary>
          <BrowserRouter>
            <>
              <NavigationBar />
              <GlobalNotification />
              <RoleCheck allowed={['ADMIN', 'PARTNER', 'MANAGER', 'CREATOR']} component={<SecondaryNavigation />} />
              <Breadcrumbs />

              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={SignInPage} />
                  <Route exact path="/error-page" component={ErrorComp} />
                  <Route exact path="/signin" component={SignInPage} />
                  <Route exact path="/reset-password" component={SignInPage} />
                  <Route exact path="/set-password" component={SignInPage} />
                  <AuthenticatedRoute exact path="/home" component={Home} />
                  <AuthenticatedRoute exact path="/content-list/:moduleId" component={ContentList} />
                  <AuthenticatedRoute exact path="/mcq/:trackingId" component={MCQView} />
                  <AuthenticatedRoute exact path="/presentation/:trackingId" component={PresentationView} />
                  <AuthenticatedRoute exact path="/carousel/:trackingId" component={CarouselView} />
                  <AuthenticatedRoute exact path="/story/:trackingId" component={StoryView} />
                  <AuthenticatedRoute exact path="/mixed/:trackingId" component={MixedView} />
                  <AuthenticatedRoute exact path="/my-account" component={UserSettings} />
                  <AuthenticatedRoute notallowed="EMPLOYEE CREATOR" exact path="/dashboard" component={Statistics} />
                  <AuthenticatedRoute notallowed="EMPLOYEE" exact path="/modules-content" component={ModulesContentDashboard} />
                  <AuthenticatedRoute notallowed="EMPLOYEE" exact path="/org/:orgid/content/:type" component={ContentCreate} />
                  <AuthenticatedRoute notallowed="EMPLOYEE" exact path="/org/:orgid/content/:type/:id" component={ContentCreate} />
                  <AuthenticatedRoute notallowed="EMPLOYEE" exact path="/org/:orgid/module" component={ModuleCreate} />
                  <AuthenticatedRoute notallowed="EMPLOYEE" exact path="/org/:orgid/module/:id" component={ModuleCreate} />
                  <AuthenticatedRoute notallowed="EMPLOYEE CREATOR" exact path="/user-management" component={UsersAndGroups} />
                  <AuthenticatedRoute notallowed="EMPLOYEE CREATOR" exact path="/account-settings" component={AccountSettings} />
                  <AuthenticatedRoute notallowed="EMPLOYEE CREATOR MANAGER PARTNER" exact path="/app-settings" component={AppSettings} />
                  <AuthenticatedRoute path="/*" component={SignInPage} />
                </Switch>
              </ScrollToTop>

              <Footer />
            </>
          </BrowserRouter>
        </ErrorBoundary>
      </IntlProvider>
    </Provider>
  );
};
export default App;
