import React, { Fragment, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { get, isEmpty, pick } from 'lodash';
import IconWrapper from 'atoms/IconWrapper';
import Button from 'atoms/Button';
import { getFalsyValue } from 'utils/calculationUtilities';
import Modal from 'atoms/Modal';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { HistoryPropTypesShape } from 'utils/core-proptypes';

import { fetchContentStart } from '../../ContentList/actions';
import MCQItemView from './MCQItemView';
import MRQItemView from './MRQItemView';
import BlogItemView from './BlogItemView';
import MixedStatus from './MixedStatus';
import { mixedComponentSubmitAction } from './actions';
import './mixed-view.scss';

const MixedView = ({ contentData, history, moduleId, contentId, langCode, attemptId, trackingId, currentContent, contentSpecificPreferences, ...props }) => {
  const t = useFormatMessage();
  const [attemptedMixedItem, setAttemptedMixedItem] = useState({});
  const [showMixedItemIndex, setShowMixedItemIndex] = useState(1);
  const [currentAnswered, setCurrentAnswered] = useState(0);
  const [mixedWarningShow, setMixedWarningShow] = useState(false);
  const content = isEmpty(contentData.details) ? currentContent : contentData;

  const componentList = content?.componentList ? [...content.componentList] : [];
  const requiredComponentIds = content?.requiredComponents ? [...content.requiredComponents] : [];

  useEffect(() => {
    if (!props.isPreview && !contentId && !moduleId) {
      history.replace('/home');
    }
  }, [props.isPreview, contentId, moduleId]);

  useEffect(() => {
    // default cases in case of handle retry
    let initialAttemptedItems = {};
    // eslint-disable-next-line no-unused-vars
    for (const item of componentList) {
      if (item.componentType === 'BLOG') {
        initialAttemptedItems[item._id] = { success: true };
      }
    }
    setAttemptedMixedItem({ ...initialAttemptedItems });
    setShowMixedItemIndex(1);
    setCurrentAnswered(0);
  }, [attemptId]);

  const preferences = props.preferences || contentSpecificPreferences;
  const { passPercent, showContentOnDiploma, displayQuestionMode, noOfTestAttempts, askForConfirmation } = preferences;

  const passCount = passPercent ? (passPercent / 100) * requiredComponentIds.length : requiredComponentIds.length;

  const startContentAgain = () => {
    !props.isPreview && props.fetchContentStart({ moduleId, contentId, langCode, isPublic: !props.isAuthenticated, trackingId });
  };

  const redirectToContentList = () => {
    if (!props.isAuthenticated) {
      history.replace(`/content-list/${moduleId}?tid=${trackingId}`);
    } else {
      history.replace(`/content-list/${moduleId}`);
    }
  };

  const handleGoToModules = () => {
    if (getFalsyValue(askForConfirmation)) {
      setMixedWarningShow(true);
    } else {
      redirectToContentList();
    }
  };

  const handleShowCompletionWarning = () => {
    setMixedWarningShow(false);
    redirectToContentList();
  };

  const handleSubmit = ({ key, success, index }) => {
    setAttemptedMixedItem({ ...attemptedMixedItem, [key]: { success } });
    setCurrentAnswered(index + 1);
  };

  return (
    <Fragment>
      <div className="mixed-view">
        {componentList.map((item, index) => {
          const showAllMode = showMixedItemIndex === componentList.length && Object.keys(attemptedMixedItem).length === componentList.length;
          switch (item.componentType) {
            case 'MCQ':
              return (
                <MCQItemView
                  _id={item._id || item.componentData._id}
                  index={index}
                  key={`mcq-${attemptId}-${item._id || item.componentData._id}`}
                  moduleId={moduleId}
                  contentId={contentId}
                  attemptId={attemptId}
                  componentData={item.componentData}
                  totalItems={componentList.length}
                  mixedComponentSubmit={props.mixedComponentSubmit}
                  showMixedItemIndex={showMixedItemIndex}
                  showAllMode={showAllMode}
                  onSubmit={handleSubmit}
                  {...props}
                  preferences={preferences}
                />
              );
            case 'MRQ':
              return (
                <MRQItemView
                  _id={item._id || item.componentData._id}
                  index={index}
                  key={`mrq-${attemptId}-${item._id || item.componentData._id}`}
                  moduleId={moduleId}
                  contentId={contentId}
                  attemptId={attemptId}
                  componentData={item.componentData}
                  totalItems={componentList.length}
                  mixedComponentSubmit={props.mixedComponentSubmit}
                  showMixedItemIndex={showMixedItemIndex}
                  showAllMode={showAllMode}
                  onSubmit={handleSubmit}
                  {...props}
                  preferences={preferences}
                />
              );
            case 'BLOG':
              return (
                <BlogItemView
                  index={index}
                  key={`blog-${attemptId}-${item._id || item.componentData._id}`}
                  moduleId={moduleId}
                  contentId={contentId}
                  attemptId={attemptId}
                  componentData={item.componentData}
                  totalItems={componentList.length}
                  showAllMode={showAllMode}
                  showMixedItemIndex={showMixedItemIndex}
                  {...props}
                  preferences={preferences}
                />
              );
            default:
              return null;
          }
        })}
        {displayQuestionMode === 'ONE_BY_ONE' &&
          showMixedItemIndex < componentList.length &&
          (currentAnswered + 1 <= showMixedItemIndex && componentList[showMixedItemIndex - 1].componentType !== 'BLOG' ? (
            <Button className="mixed-view__next-question" disabled variant="primary">
              {t('common/next')}
            </Button>
          ) : (
            <Button
              className="mixed-view__next-question"
              alt="Next Question"
              onClick={() => {
                setShowMixedItemIndex(showMixedItemIndex + 1);
              }}
              variant="primary"
            >
              {t('common/next')}
            </Button>
          ))}
        <MixedStatus
          isPreview={props.isPreview}
          isAuthenticated={props.isAuthenticated}
          showStatus={displayQuestionMode === 'ALL' ? true : showMixedItemIndex === componentList.length}
          totalCount={requiredComponentIds.length}
          passCount={passCount}
          attempted={pick(attemptedMixedItem, requiredComponentIds)}
          showContentOnDiploma={showContentOnDiploma}
        />
        {!props.isPreview ? (
          <div className="mixed-view__bottom-buttons">
            <div className="justify-content-center">
              <IconWrapper
                type="GoToModules"
                role="button"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleGoToModules();
                  }
                }}
                tabIndex={0}
                onClick={handleGoToModules}
                alt="Go to Modules"
                className="action-icons"
                style={{ marginLeft: '22px' }}
              />
            </div>
            {noOfTestAttempts !== 'ONE' && (
              <IconWrapper
                onClick={startContentAgain}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    startContentAgain();
                  }
                }}
                tabIndex={0}
                type="Retry"
                alt="Retry"
                className="action-icons"
              />
            )}
          </div>
        ) : (
          <div className="mixed-view__fake-space" />
        )}
      </div>
      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t('carousel-warning-text')}</p>}
        show={mixedWarningShow}
        onSave={handleShowCompletionWarning}
        onHide={() => setMixedWarningShow(false)}
        isAlert
        className="language-selector"
      />
    </Fragment>
  );
};

MixedView.propTypes = {
  contentData: PropTypes.shape({
    _id: PropTypes.string,
    details: PropTypes.shape({}),
    componentList: PropTypes.array
  }),
  currentContent: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  moduleId: PropTypes.string,
  contentId: PropTypes.string,
  langCode: PropTypes.string,
  attemptId: PropTypes.string,
  trackingId: PropTypes.string,
  mixedComponentSubmit: PropTypes.func,
  fetchContentStart: PropTypes.func,
  preferences: PropTypes.shape({ shuffleAnswerChoices: true }),
  contentSpecificPreferences: PropTypes.shape({ shuffleAnswerChoices: true }),
  history: HistoryPropTypesShape
};

MixedView.defaultProps = {
  contentData: {
    details: {},
    componentList: []
  },
  isPreview: false,
  currentContent: null,
  preferences: null
};

const mapStateToProps = ({ userState, userActivityState }) => {
  const isAuthenticated = !!get(userState, 'user.user._id', false);
  const currentContent = userActivityState.currentContent && get(userActivityState, 'currentContent.content.data', {});
  const moduleId = get(userActivityState, 'currentModule._id', '');
  const contentId = get(userActivityState, 'currentContent.content.contentId', '');
  const langCode = get(userActivityState, 'currentContent.content.langCode', '');
  const attemptId = get(userActivityState, 'currentContent.attemptId', '');
  const trackingId = get(userActivityState, 'currentContent.trackingId', '');
  const contentSpecificPreferences = get(userActivityState, 'currentContent.content.data.preferences', {});

  return {
    isAuthenticated,
    currentContent,
    moduleId,
    contentId,
    langCode,
    attemptId,
    trackingId,
    contentSpecificPreferences
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mixedComponentSubmit: bindActionCreators(mixedComponentSubmitAction, dispatch),
    fetchContentStart: bindActionCreators(fetchContentStart, dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MixedView));
