import { ASSETS_BASE_URL, BASE_URL } from './api';
import { isEmpty } from 'lodash';
import NoImage from '../assets/icons/noimage.svg';

export const mediaSelector = ({ media }) => {
  let { path, key } = media;
  if (media.type === 'FILE' && media.metaData) {
    path = media.metaData.path;
    key = media.metaData.key;
  }
  const basePath = ASSETS_BASE_URL || `${BASE_URL}/assets`;
  const file = `${basePath}${path}/${key}`;
  if (isEmpty(media)) return NoImage;
  return file;
};

export const mcqListSelector = ({ mcqList }) => {
  const list =
    mcqList &&
    mcqList.map(mcq => {
      const mapMcq = {
        ...mcq,
        correct: Object.keys(mcq.options).indexOf(mcq.correct),
        options: Object.values(mcq.options)
      };

      return mapMcq;
    });
  return list;
};

// export const mcrListSelector = ({ mcrList }) => {
//   const list =
//     mcrList &&
//     mcrList.map(mcr => {
//       let correctOptions = mcr.correct.map(correctOption => {
//         Object.keys(mcr.options).indexOf(correctOption);
//       });
//       const mapMCR = {
//         ...mcr,
//         correct: correctOptions,
//         options: Object.values(mcr.options)
//       };

//       return mapMCR;
//     });
//   return list;
// };

export const mixedListSelector = ({ componentList }) => {
  const list = componentList.map(item => {
    if (item.componentType === 'MCQ') {
      const mcq = item.componentData;
      const mapMcq = {
        ...mcq,
        _id: item._id,
        componentType: item.componentType,
        correct: mcq.options.findIndex(x => mcq.correct === x.id),
        options: mcq.options.map(x => x.answer)
      };
      return mapMcq;
    } else if (item.componentType === 'MRQ') {
      const mcr = item.componentData;
      let correctOptions = mcr.correct.map(correctOption => {
        // const optionsKeys = Object.keys(mcr.options);
        // const correctoption
        return mcr.options.findIndex(x => x.id === correctOption);
      });
      const mapMCR = {
        ...mcr,
        _id: item._id,
        componentType: item.componentType,
        correct: correctOptions,
        options: mcr.options.map(x => x.answer)
      };

      return mapMCR;
    } else if (item.componentType === 'BLOG') {
      return {
        componentType: item.componentType,
        _id: item._id,
        ...item.componentData
      };
    }
  });
  return list;
};
