import { COOKIE } from 'utils/constants';
import Cookies from 'js-cookie';

export const BASE_URL = process.env.REACT_APP_API_URL;

export const ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_BASE_URL;

export const token = () => Cookies.get(COOKIE.TOKEN_NAME) || '';

export const axiosConfig = contentType => {
  const headers = {
    Authorization: `Bearer ${token()}`,
    'Content-Type': contentType
  };
  const config = {
    headers
  };
  return config;
};
