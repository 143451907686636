import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { keyBy, get, partition, sortBy } from 'lodash';
import moment from 'moment';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { ModulesPropTypesShape, HistoryPropTypesShape } from 'utils/core-proptypes';
import Placeholder from 'atoms/Placeholder';
import { mediaSelector } from 'utils/selectors';
import { getPriortizedDataAccordingToLanguage } from 'utils/calculationUtilities';
import AssignedModuleCard from './AssignedModuleCard';
import PageHeader from '../PageHeader';
import { fetchLocaleLanguages } from '../../actions';
import { fetchAssignedModules } from './actions';

import './home.scss';

const HomePage = ({ userDefaultLangCode, userDateFormat, ...props }) => {
  const t = useFormatMessage();
  const isMobile = useMediaQuery({ query: '(max-width: 989px)' });

  const getStatusProps = (module, userDateFormat) => {
    const [completedContents, pendingContents] = partition(module.contents, 'isContentCompleted');
    const [futurePendingContents, pastPendingContents] = partition(pendingContents, 'isStartDateInFuture');

    if (completedContents.length === module.contents.length) {
      return {
        status: 'active',
        statusIcon: 'Complete',
        statusText: t('home/you-are-up-to-date')
      };
    }

    if (pastPendingContents.length) {
      return {
        status: 'active',
        statusIcon: 'Available',
        statusText: t('home/you-have-1-or-more-available-lessons')
      };
    }

    if (futurePendingContents.length) {
      const nextAvailableDate = moment(sortBy(futurePendingContents, 'startDate')[0].startDate).format(userDateFormat);
      if (futurePendingContents.length === module.contents.length) {
        return {
          status: 'inactive',
          statusIcon: 'Waiting',
          statusText: `${t('home/available-on')} ${nextAvailableDate}`
        };
      }
      return {
        status: 'active',
        statusIcon: 'Waiting',
        statusText: `${t('home/next-module-available-on')} ${nextAvailableDate}`
      };
    }

    return {
      status: 'inactive',
      statusIcon: 'Waiting',
      statusText: `${t('home/available-soon')}`
    };
  };

  useEffect(() => {
    props.fetchAssignedModules();
  }, []);

  useEffect(() => {
    props.fetchLocaleLanguages();
  }, []);

  const availableLanguages = props.localLanguages;
  const getLanguagesAvailable = languages => {
    return languages.map(langCode => {
      const foundLanguage = availableLanguages[langCode];
      return foundLanguage ? foundLanguage.language : '';
    });
  };

  const getRequiredProps = module => {
    const foundModuleData = getPriortizedDataAccordingToLanguage([userDefaultLangCode, module.defaultLangCode], module.data);
    const requiredProps = {
      id: module._id,
      linkTo: `/module/${module._id}`,
      title: foundModuleData ? foundModuleData.moduleTitle : '',
      languages: getLanguagesAvailable(module.languages),
      thumbnail: mediaSelector({ media: module.thumbnail }),
      ...getStatusProps(module, userDateFormat)
    };
    return requiredProps;
  };

  const onModuleClick = ({ id }) => {
    props.history.push(`/content-list/${id}`);
  };

  const handleOnEnterModule = (e, id) => {
    if (e.key === 'Enter') {
      onModuleClick({ id });
    }
  };

  const { firstName, lastName, modules, assignedModuleSuccess, localLanguagesSuccess } = props;
  let noOfPlaceholders;
  if (modules.length === 0) {
    noOfPlaceholders = 2;
  } else if (modules.length === 1) {
    noOfPlaceholders = 1;
  } else {
    noOfPlaceholders = 0;
  }
  if (isMobile && noOfPlaceholders) {
    noOfPlaceholders--;
  }
  return (
    assignedModuleSuccess &&
    localLanguagesSuccess && (
      <Container className="HomePage-container">
        <PageHeader
          className="HomePage-container__header"
          title={`${t('home/hello')} ${firstName} ${lastName}`}
          desc={modules.length === 0 ? t('home/havent-assigned-text') : t('home/below-is-all-the-course-that-have-been-assigned-to-you-learn-and-have-fun')}
        />
        <Row className="HomePage-container__card">
          {modules.map(module => (
            <Col key={module._id} xs={12} sm={6}>
              <AssignedModuleCard onClick={() => onModuleClick({ id: module._id })} handleOnEnterModule={e => handleOnEnterModule(e, module._id)} key={module._id} {...getRequiredProps(module)} />
            </Col>
          ))}
          {noOfPlaceholders > 0 &&
            Array(noOfPlaceholders)
              .fill(1)
              .map(() => (
                <>
                  <Col key={module._id} xs={12} sm={6}>
                    <Placeholder />
                  </Col>
                </>
              ))}
        </Row>
      </Container>
    )
  );
};

HomePage.propTypes = {
  fetchAssignedModules: PropTypes.func.isRequired,
  fetchLocaleLanguages: PropTypes.func.isRequired,
  userDateFormat: PropTypes.string.isRequired,
  modules: ModulesPropTypesShape,
  localLanguages: PropTypes.shape({}),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  history: HistoryPropTypesShape,
  userDefaultLangCode: PropTypes.string.isRequired,
  assignedModuleSuccess: PropTypes.bool.isRequired,
  localLanguagesSuccess: PropTypes.bool.isRequired
};

HomePage.defaultProps = {
  modules: [],
  localLanguages: {},
  firstName: '',
  lastName: ''
};

const mapStateToProps = ({ userState, HomeState, adminState }) => {
  const isAuthenticated = get(userState, 'user.user._id', false);
  const userDateFormat = get(userState, 'user.user.settings.dateFormat', 'DD/MM/YYYY');
  const defaultLangCodeUser = get(userState, 'user.user.settings.defaultLangCode');
  return {
    isAuthenticated,
    firstName: HomeState.firstName,
    lastName: HomeState.lastName,
    modules: HomeState.modules.filter(module => module.data.length && module.contents.length),
    localLanguages: keyBy(adminState.localLanguages, 'langCode'),
    localLanguagesSuccess: adminState.localLanguagesSuccess,
    assignedModuleSuccess: HomeState.assignedModuleSuccess,
    userDefaultLangCode: defaultLangCodeUser,
    userDateFormat
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchAssignedModules: bindActionCreators(fetchAssignedModules, dispatch),
    fetchLocaleLanguages: bindActionCreators(fetchLocaleLanguages, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
