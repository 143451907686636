import React from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';
import ClampLines from 'react-clamp-lines';
import IconWrapper from 'atoms/IconWrapper';

import './card.scss';

const Card = ({ heading, onClick, statusDescription, status, cover, statusIcon, showProgress, description }) => {
  const handleOnEnter = e => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      className={sn('card-container', {
        'card-container--inactive': status === 'inactive',
        'card-container--active': status === 'active'
      })}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={handleOnEnter}
    >
      <div className="card-container__course-overview">
        <img src={cover} alt="" className="card-container__card-image rounded-top" />
        <div className="card-container__content-container">
          <div className="card-container__content-container__heading-description">
            <ClampLines text={heading} id="really-unique-id" lines={2} ellipsis="..." buttons={false} className="card-container__content-container__heading" />
            <div
              className="card-container__content-container__description"
              dangerouslySetInnerHTML={{
                __html: description
              }}
            ></div>
          </div>
          {showProgress && (
            <div className="card-container__content-container__row-overview-state" style={{ height: '60px' }}>
              <div className="card-container__content-container__row-overview-state__status">{statusDescription}</div>
              <IconWrapper type={statusIcon} alt="statusIcon" className="card-container__content-container__row-overview-state__status-icon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  statusDescription: PropTypes.string.isRequired,
  statusIcon: PropTypes.string.isRequired,
  showProgress: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
export default Card;
