import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useFormatMessage } from 'hooks/useFormatMessage';

import './language-menu.scss';
/*eslint-disable*/

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  const t = useFormatMessage();

  return  <button onClick={onClick}>{t('navigation/language')}</button>;
});

const LanguageMenu = ({ publications, onSelect }) => {
  const t = useFormatMessage();
  return (
    <Dropdown
      id="dropdown-language-menu"
      alignRight={true}
      onSelect={e => {
        onSelect(e);
      }}
    >
      <Dropdown.Toggle as={CustomToggle}>{t('navigation/language')}</Dropdown.Toggle>

      <Dropdown.Menu>
        {publications.map((p, i) => {
          return (
            <>
              {i !== 0 && <Dropdown.Divider />}
              <Dropdown.Item key={p.id} eventKey={p.id}>
                {p.name}
              </Dropdown.Item>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageMenu;
