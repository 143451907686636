import React, { useState, useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Container } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';

import CustomCarousel from 'atoms/Carousel';
import IconWrapper from 'atoms/IconWrapper';
import Modal from 'atoms/Modal';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { getFalsyValue } from 'utils/calculationUtilities';
import { HistoryPropTypesShape } from 'utils/core-proptypes';
import CarouselCard from './CarouselCard';
import { trackContentCarousel } from './actions';

import './carousel-view.scss';

const CarouselView = ({ history, moduleId, contentId, attemptId, trackingId, contentData, currentContent, contentSpecificPreferences, trackContentCarousel, isPreview, ...props }) => {
  const t = useFormatMessage();
  const isMobile = useMediaQuery({ query: '(max-width: 989px)' });
  const [presentationWarningShow, setCarouselWarningShow] = useState(false);
  const [visitedPages, setVisitedPages] = useState(new Set([0]));
  const { askForConfirmation } = contentSpecificPreferences || props.preferences;

  const carouselPages = useMemo(() => {
    const content = isEmpty(contentData.details) ? currentContent : contentData;
    return get(content, 'carouselPages', []);
  }, [contentData, currentContent]);

  useEffect(() => {
    if (!isPreview && !contentId && !moduleId) {
      history.replace('/home');
    }
  }, [isPreview, contentId, moduleId]);

  useEffect(() => {
    if (!isPreview) {
      const carouselId = carouselPages[0]._id;
      trackContentCarousel({ moduleId, contentId, carouselId, payload: { attemptId, page: 0 }, isPublic: !props.isAuthenticated });
    }
  }, []);

  const redirectToContentList = () => {
    if (!props.isAuthenticated) {
      history.replace(`/content-list/${moduleId}?tid=${trackingId}`);
    } else {
      history.replace(`/content-list/${moduleId}`);
    }
  };

  const handleGoToModules = () => {
    if (getFalsyValue(askForConfirmation)) {
      setCarouselWarningShow(true);
    } else {
      redirectToContentList();
    }
  };

  const handelCarouselModal = () => {
    setCarouselWarningShow(false);
    redirectToContentList();
  };

  const handleSlideChange = slideIndex => {
    setVisitedPages(new Set(visitedPages).add(slideIndex));
    if (!isPreview) {
      const carouselId = carouselPages[slideIndex]._id;
      trackContentCarousel({ moduleId, contentId, carouselId, payload: { attemptId, page: slideIndex }, isPublic: !props.isAuthenticated });
    }
  };

  return (
    <>
      <div className="carousel-view">
        <Fragment>
          <Container className="p-0">
            <CustomCarousel onChangeSlide={handleSlideChange} alignDots={isMobile ? 'top' : 'bottom'}>
              {carouselPages.map(item => (
                <CarouselCard key={item._id} carouselData={item} />
              ))}
            </CustomCarousel>
            {!isPreview && (
              <div className="justify-content-center">
                <IconWrapper
                  tabIndex={0}
                  type="GoToModules"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleGoToModules();
                    }
                  }}
                  alt="Go to Modules"
                  onClick={() => handleGoToModules()}
                  className="action-icons mt-4 mb-4"
                  style={{ marginLeft: '22px' }}
                />
              </div>
            )}
          </Container>
          <Modal
            heading={t('common/modal/please-confirm-text')}
            modalBody={<p>{t('carousel-warning-text')}</p>}
            show={presentationWarningShow}
            onSave={handelCarouselModal}
            onHide={() => setCarouselWarningShow(false)}
            isAlert
            className="language-selector"
          />
        </Fragment>
      </div>
    </>
  );
};

CarouselView.propTypes = {
  contentData: PropTypes.shape({
    _id: PropTypes.string,
    details: PropTypes.shape({})
  }),
  history: HistoryPropTypesShape,
  contentSpecificPreferences: PropTypes.shape({}),
  currentContent: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  contentId: PropTypes.string,
  moduleId: PropTypes.string,
  preferences: PropTypes.shape({
    showContentInOrder: true
  }),
  trackContentCarousel: PropTypes.func.isRequired,
  langCode: PropTypes.string,
  attemptId: PropTypes.string,
  trackingId: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired
};

CarouselView.defaultProps = {
  contentData: {
    details: {}
  },
  currentContent: null,
  isPreview: false,
  preferences: {}
};

const mapStateToProps = ({ userState, userActivityState }) => {
  const isAuthenticated = get(userState, 'user.user._id', false);
  const currentContent = userActivityState.currentContent && get(userActivityState, 'currentContent.content.data', {});
  const moduleId = get(userActivityState, 'currentModule._id', '');
  const contentId = get(userActivityState, 'currentContent.content.contentId', '');
  const langCode = get(userActivityState, 'currentContent.content.langCode', '');
  const attemptId = get(userActivityState, 'currentContent.attemptId', '');
  const trackingId = get(userActivityState, 'currentContent.trackingId', '');
  const contentSpecificPreferences = get(userActivityState, 'currentContent.content.data.preferences', {});

  return {
    isAuthenticated,
    currentContent,
    contentId,
    moduleId,
    attemptId,
    trackingId,
    langCode,
    contentSpecificPreferences
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackContentCarousel: bindActionCreators(trackContentCarousel, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarouselView));
